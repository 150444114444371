import axios from 'axios';
import store from './store';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: [(data, headers) => {
    // If the data is already a string, return it as is
    if (typeof data === 'string') return data;
    // Otherwise, stringify it
    return JSON.stringify(data);
  }],
});

// List of protected endpoint prefixes
const protectedEndpoints = [
  'user/',
  'coin/star/',
  'coin/draft/',
];

// Helper function to check if an endpoint is protected
const isProtectedEndpoint = (url) => {
    const result = protectedEndpoints.some(endpoint => 
        url.startsWith(endpoint.replace(/\/$/, '')) || url.startsWith(endpoint)
    );
    // const result=protectedEndpoints.some(endpoint => url.startsWith(endpoint));
    return result;
};

// request interceptor
api.interceptors.request.use(
  (config) => {
    // Check if the data is FormData
    if (config.data instanceof FormData) {
      // If it's FormData, set the correct content type
      config.headers['Content-Type'] = 'multipart/form-data';
      // WILL NOT WORK !!
      // Append session and network to FormData
      config.data.append('session', store.state.sessionToken);
    } else {
      // For JSON data, proceed as before
      config.data = {
        ...config.data,
        session: store.state.sessionToken,
        // network: store.state.currentNetwork
      };
    }

    // alert('API interceptor request URL '+config.url+' protected='+isProtectedEndpoint(config.url)+' JWT='+store.state.jwt);

    //// Option 1.

    // Check if the endpoint is protected
    // if (isProtectedEndpoint(config.url)) {
    //     const jwt = store.state.jwt;
    //     if (!jwt) {
    //       // If there's no JWT for a protected route, cancel the request
    //       return false; //Promise.reject(new axios.Cancel('No JWT token available for protected route'));
    //     }
    //     // Include JWT for protected endpoints
    //     config.data.auth = jwt;
    //   }

        // Include JWT only for protected endpoints
        // if (isProtectedEndpoint(config.url) && store.state.jwt) {
        //     // alert('interceptor - include jwt: ' + store.state.jwt);
        //     config.data.auth = store.state.jwt;
        // }
        //// Option 2. Include in bearer

        // Adds JWT as Bearer token for protected routes
        if (isProtectedEndpoint(config.url)) {
          const jwt = store.state.jwt;
          if (jwt) {
            config.headers['Authorization'] = `Bearer ${jwt}`;
          } else {
            // If there's no JWT cancel the request
            return Promise.reject(new Error('No JWT token available for protected route'));
          }
        }


    return config;
  },
  (error) => Promise.reject(error)
);

// response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // alert('answer intercepted '+error.response.status + ' url='+originalRequest.url+' originalRequest._retry'+originalRequest._retry);
    // Only attempt to refresh token for protected endpoints
    if (error.response && error.response.status && error.response.status === 401 
        && isProtectedEndpoint(originalRequest.url) 
        && store.state.jwt
        && !originalRequest._retry) {
      originalRequest._retry = true;
      try {

        console.log('API response interceptor - dispatch refreshToken');
        const refreshResult = await store.dispatch('refreshToken');
        
        console.log ('API response interceptor - refreshToken dispatched, returns '+JSON.stringify(refreshResult));
        if (refreshResult.status === 'success') {

            // Parse the original data
            let originalData;
            try {
                originalData = JSON.parse(originalRequest.data);
            } catch (e) {
                console.error('API response interceptor - Error parsing original request data:', e);
                originalData = {};
            }
            
            // Update the auth token
            originalData.auth = refreshResult.jwt;
            
            // Reassign the updated data
            originalRequest.data = originalData;

            console.log('API response interceptor - retrying request with new data:', originalRequest.data);
            
            // Retry the request with the updated configuration
            return api(originalRequest);

        }

      } catch (refreshError) {
        console.log('API response interceptor - ERROR (WRONG TOKEN): '+JSON.stringify(refreshError));
        // await store.dispatch('logout');
        await logoutUser();
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

// Function to handle logout
async function logoutUser() {
    try {
      // Clear auth state in Vuex store
      console.log('logoutUser');
      await store.dispatch('logout');
      // Redirect to login page
      router.push('/user');
    } catch (logoutError) {
      console.error('Error during logout:', logoutError);
    }
  }

export default api;

// control unauthorized acess to endpoint

// api.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//       const originalRequest = error.config;
//       if (error.response.status === 401 && isProtectedEndpoint(originalRequest.url)) {
//         if (!originalRequest._retry) {
//           originalRequest._retry = true;
//           try {
//             const refreshResult = await store.dispatch('refreshToken');
//             if (refreshResult.status === 'success') {
//               originalRequest.data.auth = refreshResult.jwt;
//               return api(originalRequest);
//             }
//           } catch (refreshError) {
//             await store.dispatch('logout');
//             throw new Error('UNAUTHORIZED_ACCESS');
//           }
//         } else {
//           // If we've already tried to refresh the token, throw an unauthorized error
//           throw new Error('UNAUTHORIZED_ACCESS');
//         }
//       }
//       return Promise.reject(error);
//     }
//   );