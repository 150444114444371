<template>

    <div @click="hideSign()" class="fixed z-[100] inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center">
        <div @click.stop class="bg-slate-800 p-6 rounded-lg shadow-xl w-[80%] max-w-[500px]">
            <div class="flex flex-row justify-between">
                <h2 class="font-bold mb-4">Sign In</h2>
                <div @click="hideSign()" class="p2 cursor-pointer" ><img src="@/assets/set/close.svg" class="w-5 h-5"></div>
                <!-- border-slate-400 rounded-full -->
            </div>
            <div class="flex flex-col gap-3 border border-slate-600 p-4 rounded-xl mb-4">
                <h3 class="font-bold">Enter Your E-mail</h3>
                <input type="text" class="text" placeholder="hi@air.fun">
                <button target="_blank" class="b3 bc">
                    <span>Send Link</span>
                </button>
            </div>

            <div class="flex flex-col gap-3 border border-slate-600 p-4 rounded-xl mb-4">
                <h3 class="font-bold">Use Telegram</h3>

                <div class="">Aceess your Aripump account by authorising access in the Telegram bot:</div>
                <a href="https://t.me/airpumpbot?start=A_34mfsoefk293fidsfngijg3" target="_blank" class="b3 bc">
                    <span>Open <img src="@/assets/social/t-b.svg" class="w-5 h-5 inline-block mb-1 mx-1"> Telegram</span>
                </a>
                <div class="">Download Telegram messanger app to authentificate.</div>
            </div>
            <div class="flex flex-col gap-3 border border-slate-600 p-4 rounded-xl mb-4">
                <h3 class="font-bold">Twitter</h3>
                <a href="https://t.me/airpumpbot?start=A_34mfsoefk293fidsfngijg3" target="_blank" class="b3 bc">
                    <span>Open <img src="@/assets/social/x-b.svg" class="w-5 h-5 inline-block mb-1 mx-1"> X (Twitter)</span>
                </a>
            </div>
            <div class="flex flex-col gap-3 border border-slate-600 p-4 rounded-xl mb-4">
                <h3 class="font-bold">Google</h3>
                <a href="https://t.me/airpumpbot?start=A_34mfsoefk293fidsfngijg3" target="_blank" class="b3 bc">
                    <span>Google</span>
                </a>
            </div>

            <div class="my-2 flex items-center">
                <div class="flex-grow h-px bg-slate-600"></div>
                <div class="mx-4 text-slate-300">or</div>
                <div class="flex-grow h-px bg-slate-600"></div>
            </div>

            <div class="mb-2">Sign In using your Solana wallet</div>
            <div class="flex flex-col gap-3">
                <button @click="showConnect()"  class="b3 ba">Connect Wallet</button>
            </div>

        </div>
    </div>

    <!-- 
    <div v-show="authModal" class="p-5 fixed top-5 bottom-5 left-5 right-5 z-[99] overflow-y-auto bg-slate-900 border border-yellow-400">
        <h3 class="mb-3">Login with E-mail</h3>
        <div class="mb-5">
            [enter e-mail]
        </div>
        
        <h3 class="mb-4">Login with Telegram</h3>

            <div class="w-full text-center mb-5 mt-2">

            </div>

    </div> 
    -->

</template>

<script>

// import { mapState, mapActions } from 'vuex';

export default {
    props: {
        // openSign: {
        //     type: Boolean,
        //     required: true
        // }
    },
    // data() {
    //     return {
    //         showNotify: true
    //     }
    // },
    methods: {
        showConnect() {
            this.$emit('showConnect');
        },
        hideSign() {
            this.$emit('hideSign');
        }
    }
}

</script>