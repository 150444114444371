import api from '@/api';

const actions = {

    async getUserData({ commit }, jwt) { // used in loadSession - only if there is JWT
        try {
            const response = await api.post('user/data');
            if(response.data && response.data.user_data) {
                commit('SET_USER', response.data.user_data);
            }
            //   const response = await axios.post(process.env.VUE_APP_API_URL+'user/data',
            //         { auth: jwt }
            //   );
            //   if(response.data && response.data.user_data) {
            //         commit('SET_USER', response.data.user_data);
            //   }
            return response.data;
        } catch (error) {
            console.error('Failed to get user data:', error);
            throw error;
        }
    },

    async getUserInfo({ commit }, wallet) { // used in loadSession - if no jwt
        // same as getUserData but using wallet address if there is no jwt
        try {
            console.log('getUserInfo for wall='+ wallet);
            const response = await api.post('wall/info',{
                wall: wallet
            });
            if(response.data && response.data.user_data) {
                commit('SET_USER', response.data.user_data);
            }
            return response.data;
        } catch (error) {
            console.error('Failed to get user data:', error);
            // throw error;
        }
    },

};

export default actions;