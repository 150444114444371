import axios from 'axios';

const actions = {

    async initSession({ dispatch, commit, state }) {

        try {

            console.log('Init app'); // !!
            const response = await axios.post(process.env.VUE_APP_API_URL+'sess/init', {
                language: navigator.language || navigator.userLanguage,
                user_agent: navigator.userAgent,
            });
            if (response.data.status === 'success') {
                // alert('!'+JSON.stringify(response.data));
                commit('SET_APP_DATA',response.data.public_data);
            } else {
            throw new Error(response.data.message || 'Failed to load session');
            }

            // console.log('get public user data');
            // await axios.post(process.env.VUE_APP_API_URL+'sess/init',{ data: {
            //     language: navigator.language || navigator.userLanguage,
            //     user_agent: navigator.userAgent,
            // }}).then((response) => {
            // }).catch(error => {      
            // });

            const sessionData = {
                language: navigator.language || navigator.userLanguage,
                user_agent: navigator.userAgent,
                utm_source: state.utmSource,
                utm_data: state.utmData
            };
            
            console.log('initSession wif data: '+JSON.stringify(sessionData));
            if (state.sessionToken) {
                console.log('2 - There is token - do load session: '+state.sessionToken+' data: '+JSON.stringify(sessionData));
                dispatch('loadSession', { sessionToken: state.sessionToken, sessionData })
                .then(() => {
                    if(state.jwt) {
                        console.log('! - There is jwt - get userdata');
                        dispatch('getUserData', state.jwt);
                    } else if(state.walletAddr) {
                        dispatch('getUserInfo', state.walletAddr);
                    }
                })
                .catch(error => {
                    console.warn('Failed to load existing session:', error);
                    localStorage.removeItem('sessionToken');
                    console.log('3 - Error loading session ('+error+') - do createSession');
                    dispatch('createSession', sessionData);
                });
            } else {
                    console.log('2 - No session - do createSession');
                    dispatch('createSession', sessionData);
            }

            commit('SET_APP_LOAD', true );
            
        } catch (error) {
            console.error('Error loading session:', error);
            commit('SET_APP_ERROR', { code: 'Init', text: error });
            throw error;
        }

    },

    async loadSession({ commit }, { sessionToken, sessionData }) {
        try {
            console.log('Loading session: '+sessionToken+' wif data: '+JSON.stringify(sessionData)); // !!
            const response = await axios.post(process.env.VUE_APP_API_URL+'sess/load', {
            session: sessionToken,
            data: sessionData,
            });
            if (response.data.status === 'success') {
                console.log('Session loaded: '+sessionToken);
                commit('SET_SESSION_TOKEN', sessionToken);
                return true;
            } else {
            throw new Error(response.data.message || 'Failed to load session');
            }
        } catch (error) {
            console.error('Error loading session:', error);
            throw error;
        }
    },

    async createSession({ commit }, sessionData) {
        try {
            const response = await axios.post(process.env.VUE_APP_API_URL+'sess/create', {
            data: sessionData
            });
            if (response.data.status === 'success') {
                console.log('Session created: '+response.data.sessionToken);
                commit('SET_SESSION_TOKEN', response.data.sessionToken);
                return true;
            } else {
            throw new Error(response.data.message || 'Failed to create session');
            }
        } catch (error) {
            console.error('Error creating session:', error);
            throw error;
        }
    },

};

export default actions;