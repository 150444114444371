<template>

<div class="relative min-h-screen bg-[url('@/assets/bg1.png')] bg-cover">

    <!-- <div class="hidden md:block fixed inset-y-0 left-4 mi:left-0 w-3 mi:w-4 bg-blue-900"></div> -->
    <!-- <div class="hidden md:block fixed inset-y-0 left-0 w-1 mi:w-2 bg-zinc-100"></div>
    <div class="hidden md:block fixed inset-y-0 left-1 mi:left-2 w-2 mi:w-3 bg-green-600"></div>
    <div class="hidden md:block fixed inset-y-0 left-4 mi:left-5 w-3 mi:w-4 bg-blue-900"></div>
    <div class="hidden md:block fixed inset-y-0 right-4 mi:right-4 w-3 mi:w-4 bg-blue-900"></div>
    <div class="hidden md:block fixed inset-y-0 right-1 mi:right-2 w-2 mi:w-3  bg-green-400"></div>
    <div class="hidden md:block fixed inset-y-0 right-0 w-1 mi:w-2  bg-zinc-100"></div> -->

    <div class="fixed inset-x-0 z-[100] top-0 h-3 bg-blue-900"></div>
    <div class="fixed inset-x-0 z-[100] bottom-0 h-3 bg-red-900"></div>

    <notify :notification="notification" DEBUG="DEBUG" />
    <inform :information="currentInformation" :notification="notification" v-show="allowInform" DEBUG="DEBUG" />

    <!--
    <TXModal :showTxSuccessModal="showTxSuccessModal" :showTxErrorModal="showTxErrorModal"></TXModal> 
    <botModal :startBotShow="startBotShow" DEBUG="DEBUG" />
    -->

    <div class="min-w-[320px] min-h-screen">

        <!-- bg video
        <div v-else class="min-w-[320px] relative min-h-screen">
            <video autoplay muted loop class="absolute top-10 left-10 w-full h-full object-contain z-[-1]">
            <source src="@/assets/bgv.mp4" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        <div class="absolute inset-0 bg-black bg-opacity-30 backdrop-blur-xs"></div>
        <div class="content relative z-10 md:ml-[10%] md:p-10">
             -->

        <div class="content z-10 md:ml-[5%] mi:ml-[10%] py-5 md:py-10 md:pt-14">

            <signModal v-show="openSign" @showConnect="showConnect" @hideSign="hideSign" DEBUG="DEBUG" />

            <div class="max-md:px-5 mb-5">
                <!-- <router-link :to="{ name: 'home' }" class="mb-4"><img src="@/assets/air.svg" class="w-8 ml-2"></router-link> -->
                <router-link :to="{ name: 'home' }" class="mb-4"><img src="@/assets/logo.png" class="mt-7 w-32 md:w-44 ml-2"></router-link>
            </div>
   
            <router-view 
                :windowWidth="windowWidth" 
                :viewType="viewType"
                @connectWallet="connectWallet"
                @changeViewType="changeViewType"
                :DEBUG="DEBUG">
            </router-view>

        </div>

    </div>

</div>

</template>
  
<script>

 import { mapState, mapActions, mapGetters } from 'vuex';
//  import api from '@/api';
 // import axios from 'axios';

 import notify from '@/modals/Notify.vue';
 import inform from '@/modals/Inform.vue';
 
//  import connectModal from '@/modals/Connect.vue';
 import signModal from '@/modals/Sign.vue';
// import store from './store';


 export default {
    name: 'Airpump',
    components: {
        notify, inform,
        signModal,
        // profileModal, 
    },
    data() {
        return {

            DEBUG: false,
        
            // Data: null,
            // User: null,

            isAuth: false,
            walletRaw: '-',

            viewType: 1, // global for al lists, depends on window width
            windowWidth: window.innerWidth,

            statsNet: '-',
            statsVol: '-',

            isLoading: false,
            isError: false,
            // isErrorText: null,
            // isErrorCode: null,

            isHome: false, // watch route
            allowInform: true,

            openTour: true,
            openConnect: false,
            openSign: false,
            openPofile: false,

            phantomInstalled: null,
            solflareInstalled: null,

            search: '',
            closeSearch: false,

        }
    },
    computed: {

        // ...mapState(['notification']), // , 'information'

        // ...mapState('ws', ['infoQueue']),
        // currentInformation() {
        //     return this.infoQueue[0] || null;
        // },

        // ...mapState(['appData', 'appLoad', 'appError', 'appErrorCode', 'appErrorText']),
        // ...mapState(['user', 'jwt', 'refreshToken']),

        // isAuth() { return !!this.jwt; },

    },
    watch: {
        // walletAddr(newVal, oldVal) {
        //     console.log('Wallet address changed:', newVal);
        //     this.walletRaw = newVal;
        // },
        $route: {
            immediate: true,
            handler(to, from) {
                this.checkRoute(to);
            }
        },
        // openProfile(newValue) {
        //     if (newValue) this.scrollStop(); else this.scrollStart();
        // },
        windowWidth: {
            handler(newWidth) {
                if (newWidth < 520 && this.viewType === 1) {
                this.viewType = 2;
                }
            },
            immediate: true
        },
    },
    async created() {

        // GET parameters
        const getParams = this.getParams();
        await this.setGetParams(getParams);

        // console.log('Step 1 - Init Session');
        // await this.initSession();

        // this.statsNet = this.appData.stats.net;
        // this.statsVol = this.appData.stats.vol;

        // // Check/initialize session
        // try {
        //     await this.initSession();
        //     // Session is now initialized or loaded
        // } catch (error) {
        //     console.error('Failed to initialize/load session:', error)
        //     // Error handling is now done in the store action
        // }
        
    },

    mounted() {

        // this.initWebSocket();

        if(process.env.VUE_APP_DEBUG==1) this.DEBUG=true;

        window.addEventListener('resize', this.updateWidth);

        // Solana Wallets //

        // Double Check
        // this.phantomInstalled = this.checkPhantomInstalled();
        // this.solflareInstalled = this.checkSolflareInstalled();

        // this.initWalletListeners();
        // this.checkWalletConnection(); // ?? !!

        // window.addEventListener('message', this.handleMessage); // twitter login

    },
    methods: {

        ...mapActions(['initSession', 'setGetParams']),

        // // Double Checkers
        // checkSolflareInstalled() {
        //     return 'solflare' in window;
        // },
        // checkPhantomInstalled() {
        //     // const isPhantomInstalled = window.phantom?.solana?.isPhantom
        //     // if not - https://phantom.app/
        //     // return 'solana' in window && window.solana.isPhantom;
        //     return 'phantom' in window || 'solana' in window;
        // },

        // connectWallet() {
        //     this.showConnect();
        // },

    // Search //

    // handleSearch() {
    //   if (this.search.length > 2 || this.search.length === 0) {
    //     if (this.$route.name !== 'list') {
    //       this.$router.push({ name: 'list', query: { search: this.search } });
    //     } else {
    //       this.$router.push({ query: { ...this.$route.query, search: this.search } });
    //     }
    //   }
    //   if (this.search.length > 2) {
    //     this.closeSearch = true;
    //   }
    // },

    // finishSearch() {
    //     this.closeSearch = false;
    //     this.search = '';
    //     this.closeSearch = false;
    //     this.handleSearch();
    // },


        // Utility function to get UTM params
        getParams() {

            const urlParams = new URLSearchParams(window.location.search);
            
            const utmSource = urlParams.get('utm_source') || urlParams.get('from') || '';
            
            const utmData = {};
            for (const [key, value] of urlParams.entries()) {
                if (key.startsWith('utm_')) {
                utmData[key] = value;
                }
            }
            const utmDataJson = JSON.stringify(utmData);

            const botCode = urlParams.get('bot') || null;
            const inviteCode = urlParams.get('i') || null;

            console.log('GET' +JSON.stringify({ utmSource, utmDataJson, botCode, inviteCode }));

            return { utmSource, utmData, botCode, inviteCode };

        },

        // Utilities //
    
        scrollStop() { document.body.classList.add('no-scroll'); },
        scrollStart() { document.body.classList.remove('no-scroll'); },

        isActive(name) { return this.$route.name === name; },

        checkRoute(route) {
            console.log('Current route:', route.name);
            this.isHome = route.name === 'list';
           
            if(route.name === 'launch') {
                this.allowInform = false;
            } else {
                this.allowInform = true;
            }
        },

        // Modals //
        // showConnect() {
        //     this.openConnect = !this.openConnect;
        //     this.openSign = false;
        // },
        // hideConnect() {
        //     this.openConnect = false;
        // },
        // showSign() {
        //     this.openSign = !this.openSign;
        //     this.openConnect = false;
        // },
        // hideSign() {
        //     this.openSign = false;
        // },
        // hideProfile() {
        //     this.openProfile = false;
        // },
        // hideTour() {
        //     this.openTour = false;
        // },

        // closeBotShow() {
        //     this.$store.commit('SET_START_BOT_SHOW', false);
        // },

        // Debug //

        doDEBUG() { this.DEBUG = !this.DEBUG; },

        // Twitter Login


        // STANDALONE

        checkStandaloneMode() {
        this.isStandalone = window.matchMedia('(display-mode: standalone)').matches ||
                            window.navigator.standalone ||
                            document.referrer.includes('android-app://');
        },

        detectDevice() {
            const userAgent = window.navigator.userAgent.toLowerCase();
            this.isIOS = /iphone|ipad|ipod/.test(userAgent);
            this.isAndroid = /android/.test(userAgent);
        },
        listenForInstallPrompt() {
            window.addEventListener('beforeinstallprompt', (e) => {
                e.preventDefault();
                this.deferredPrompt = e;
                this.showInstallPrompt = true;
            });
        },
        showAndroidInstallLoader() {
            this.showAndroidLoader = true;
            setTimeout(() => {
                this.doneAndroidLoader = true;
                // this.triggerAndroidInstall();
            }, 5000);
        },
        doAndroidInstall() {
            if (this.deferredPrompt) {
                this.deferredPrompt.prompt();
                this.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                this.deferredPrompt = null;
                });
            }
            this.showAndroidLoader = false;
        },

        // View //

        changeViewType(viewType) {
            if(this.windowWidth < 420 && viewType === 1) viewType = 2; // ms
            this.viewType = viewType;
        },

        updateWidth() {
            this.windowWidth = window.innerWidth;
            if(this.windowWidth < 420 && this.viewType === 1) this.viewType = 2; // ms
        },

    
    },
        
    beforeUnmount() {
        this.removeAllListeners();
    
    },
    beforeDestroy() {
        this.scrollStop();

    },
    deactivated() {
        this.scrollStop();
    },

}
</script>

<style>
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire background */
  z-index: -1; /* Places the video behind other content */
}
.content {
  position: relative; /* Ensures content is above the video */
  z-index: 1; /* Higher z-index than the video */
}
</style>