import axios from 'axios';
import api from '../api';

const actions = {

    async refreshToken({ commit, dispatch, state }) {
        try {
        // Not using api.post here as it might create a loop with the interceptor
        console.log('Store refreshToken '+state.refreshToken);
        const response = await axios.post(process.env.VUE_APP_API_URL+'auth/refresh', {
            refresh_token: state.refreshToken
        });
        console.log('Store refreshToken response '+JSON.stringify(response.data));
        if (response.data.status === 'success') {
            commit('SET_USER', response.data.user_data);
            if(response.data.user_auth) {
                commit('SET_JWT', response.data.jwt);
                commit('SET_REFRESH_TOKEN', response.data.refresh_token );
            }
            // ?? new user // if(response.data.user_new) {}
            // ?? has bot code // if (response.data.bot_code) {
            return { jwt: response.data.jwt, status: 'success' };
        }
        } catch (error) {
        console.error('Failed to refresh token:', error);
        dispatch('showNotification', {
            message: 'Unable to refresh authorization token. Please reconnect the wallet.',
            type: 'error'
        });
        // disconnect wallet ?
        commit('CLEAR_AUTH');
        throw error;
        }
    },

    logout({ commit }) {
        console.log('Logout - Clear auth!');
        commit('CLEAR_AUTH');
    },

    async getSignMessage({ commit }, { publicKey, walletType }) {
        try {
            const response = await api.post('auth/wallet/connect', {
                data: {
                    wallet_address: publicKey,
                    wallet_type: walletType
                }
            });
            return response.data.sign_value;
        } catch (err) {
            console.error('Failed to get sign message from backend:', err);
            throw err;
        }
    },

    async authenticateWallet({ commit }, { publicKey, signedMessage, walletType }) {
        try {

            const response = await api.post('auth/wallet/verify', {
                data: {
                    public_key: publicKey,
                    signed_value: signedMessage.signature,
                    wallet_type: walletType
                }
            });

            // alert('authenticateWallet response='+JSON.stringify(response.data));
            if (response.data.status === 'success') {
                commit('SET_USER', response.data.user_data);
                if(response.data.user_auth) {
                    commit('SET_JWT', response.data.jwt);
                    commit('SET_REFRESH_TOKEN', response.data.refresh_token );
                }
                if (response.data.jwt && response.data.refresh_token) {
                    commit('SET_JWT', response.data.jwt, { root: true });
                    commit('SET_REFRESH_TOKEN', response.data.refresh_token, { root: true });
                }
            }
            return response.data;

        } catch (err) {
            console.error(`Wallet authentication error:`, err);
            throw err;
        }
    },

    async notifyWalletConnection({ state, dispatch }, { publicKey, walletType }) {
        try {
        await api.post('auth/wallet/notify', {
            data: {
            public_key: publicKey,
            wallet_type: walletType,
            session: state.sessionToken
            }
        });
        } catch (err) {
        console.error('Failed to notify backend of wallet connection:', err);
        }
    },


};

// Axios interceptor
// api.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       try {
//         const newToken = await store.dispatch('refreshToken');
//         originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
//         return api(originalRequest);
//       } catch (refreshError) {
//         return Promise.reject(refreshError);
//       }
//     }
//     return Promise.reject(error);
//   }
// );

// Use this api instance for all axios requests
// store.api = api;

export default actions;