<template>

    <!-- max-md:bottom-24 md:bottom-5 -->
    <div @click="hideNotify" v-if="notification" 
            class="z-[999] max-w-[1400px] md:w-[80%] p-2 md:p-5 mx-auto fixed 
            max-md:bottom-7 md:bottom-5 left-3 opacity-90 right-3 text-slate-200 text-center bg-slate-800 shadow-2xl rounded-[10px] md:rounded-[18px] border-slate-700 border"
            :class="{
            'text-green-600 border-green-600': notification.type === 'success',
            'text-red-600 border-red-600': notification.type === 'error',
            'text-yellow-600 border-yellow-600': notification.type === 'warning',
            'text-blue-600 border-blue-600': notification.type === 'info'
            }">
        {{ notification.message }}
    </div>

</template>

<script>

// import { mapState, mapActions } from 'vuex';

export default {
    props: {
        notification: {
            type: Object,
            required: false,
            default: null
        }
    },
    // data() {
    //     return {
    //         showNotify: true
    //     }
    // },
    methods: {
        hideNotify() {
            this.$store.dispatch('hideNotify');
            this.notification = false;
        }
    }
}

</script>