<template>

    <!-- max-md:bottom-24 md:bottom-5 -->
    <div @click="hideInform" v-if="information" 
            :key="animationKey"
            class="z-[999] max-w-[1400px] md:w-[80%] p-2 md:p-5 mx-auto fixed 
             left-3 opacity-90 right-3 text-center shadow-2xl rounded-[10px] md:rounded-[18px]  text-white text-bold border blink-shake-animation"
            :class="{
            'max-md:bottom-20 md:bottom-16': notification !== null, 
            'max-md:bottom-7 md:bottom-5': notification === null, 
            'bg-green-700 border-green-900': information.view === 'buy',
            // 'text-red-600 border-red-600': information.view === '',
            'bg-yellow-600 border-yellow-900': information.view === 'sell',
            'bg-blue-600 border-blue-900': information.view === 'new'
            }">
        {{ information.text }}
    </div>

</template>

<script>

import { mapActions } from 'vuex';

export default {
    props: {
        information: {
            type: Object,
            required: false,
            default: null
        },
        notification: {
            type: Object,
            required: false,
            default: null
        },
    },
    data() {
        return {
            animationKey: 0,
        }
    },
    watch: {
        information() {
            this.animationKey += 1;
        }
    },
    methods: {
        ...mapActions(['hideInformation']),
        hideInform() {
            this.hideInformation();
        }
    }
}

</script>
