import { createApp } from 'vue'
import App from './App.vue'

import router from './router';
import i18n from './lang';
import store from './store';

import { globalErrorHandler } from './utils/error'
import Globals from './utils/func';

import './assets/index.css';

const app = createApp(App);

window.addEventListener('unhandledrejection', event => {
    // alert('Unhandled Promise Rejection:', JSON.stringify(event));
    store.dispatch('showNotification', { 
        message: 'An unexpected error occurred. Please try again later or reload the app.', 
        type: 'error' 
      });
         // Prevent the default browser behavior
        event.preventDefault();
    // Handle the error (e.g., show a notification)
    // showErrorNotification('An unexpected error occurred. Please try again later.')
});

app.config.errorHandler = globalErrorHandler;
app.mixin(Globals);

app
.use(router)
.use(i18n)
.use(store)
.mount('#app')