const state = {
    socket: null,
    infoQueue: [],
    coinUpdateQueue: [],
    isProcessingInfoQueue: false,
    isProcessingCoinQueue: false,
};

const mutations = {
    SET_SOCKET(state, socket) {
        state.socket = socket;
    },
    ADD_TO_INFO_QUEUE(state, message) {
        state.infoQueue.push(message);
    },
    ADD_TO_COIN_QUEUE(state, update) {
        state.coinUpdateQueue.push(update);
    },
    REMOVE_FROM_INFO_QUEUE(state) {
        state.infoQueue.shift();
    },
    REMOVE_FROM_COIN_QUEUE(state) {
        state.coinUpdateQueue.shift();
    },
    SET_PROCESSING_INFO_QUEUE(state, isProcessing) {
        state.isProcessingInfoQueue = isProcessing;
    },
    SET_PROCESSING_COIN_QUEUE(state, isProcessing) {
        state.isProcessingCoinQueue = isProcessing;
    },
};

const actions = {

    initWebSocket({ commit, dispatch }) {
        const socket = new WebSocket(process.env.VUE_APP_WS_URL);
        socket.onmessage = (event) => {
            // alert('onmessage');
            const data = JSON.parse(event.data);
            if (data.type === 'info') {
                commit('ADD_TO_INFO_QUEUE', data);
                dispatch('processInfoQueue');
            } else if (data.type === 'coin') {
                commit('ADD_TO_COIN_QUEUE', data);
                dispatch('processCoinQueue');
            }
        };
        commit('SET_SOCKET', socket);
    },

    async processInfoQueue({ state, commit, dispatch }) {
        if (state.isProcessingInfoQueue || state.infoQueue.length === 0) return;

        commit('SET_PROCESSING_INFO_QUEUE', true);
        const message = state.infoQueue[0];

        // Dispatch to show information (you'll need to implement this action)
        await dispatch('showInformation', {
            message: message.text,
            type: message.view || 'buy'
        }, { root: true });

        await new Promise(resolve => setTimeout(resolve, 2000));

        commit('REMOVE_FROM_INFO_QUEUE');
        commit('SET_PROCESSING_INFO_QUEUE', false);

        if (state.infoQueue.length > 0) {
            dispatch('processInfoQueue');
        }
    },

    async processCoinQueue({ state, commit, dispatch }) {
        if (state.isProcessingCoinQueue || state.coinUpdateQueue.length === 0) return;

        commit('SET_PROCESSING_COIN_QUEUE', true);
        const update = state.coinUpdateQueue[0];

        // alert('update: '+JSON.stringify(update)); // that is update: {"type":"coin","view":"buy","coin_id":4,"text":""}

        if (update && update.type === 'coin' && update.coin_id) {
            await dispatch('handleCoinUpdate', update, { root: true });
            // Create a minimal coin update object with the available data
            // const coinUpdate = {
            //     coin_id: update.coin_id,
            //     type: update.type,
            //     view: update.view,
            //     text: update.text
            // };
            // Dispatch the handleCoinUpdate action with this minimal object
            // await dispatch('handleCoinUpdate', coinUpdate, { root: true });

        } else {
            console.error('Invalid coin update data:', update);
        }

        commit('REMOVE_FROM_COIN_QUEUE');
        commit('SET_PROCESSING_COIN_QUEUE', false);

        if (state.coinUpdateQueue.length > 0) {
            dispatch('processCoinQueue');
        }
    },

};

const getters = {
  isConnected: (state) => state.socket !== null && state.socket.readyState === WebSocket.OPEN,
};

export default { namespaced: true, state, mutations, actions, getters };