import { createRouter, createWebHistory } from 'vue-router';

const routes = [

  { path: '/', name: 'home', component: () => import('@/pages/Home.vue'), props: true },
  { path: '/access', name: 'access', component: () => import('@/pages/Access.vue'), props: true },
  // { path: '/about', name: 'about', component: () => import('@/pages/About.vue'), props: true },
  // { path: '/feedback', name: 'feedback', component: () => import('@/pages/Feedback.vue'), props: true },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
                const element = document.querySelector(to.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            return { top: 0 } // return { x: 0, y: 0 }
        }
      },
  });

router.beforeEach((to, from, next) => {
  console.log('Navigation to:', to.name);
  // const isAuthenticated = !!localStorage.getItem('wallet');
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next({ name: 'Home' });
  } else {
    next();
  }
});

export default router;